.navbar-nav-row-1 .nav-link:nth-child(1) img {
  margin-right: 7px;
}
.navbar-nav-row-1 .nav-link:nth-child(2) img {
  margin-right: 15px;
}
header .navbar {
  border-top: 48px solid var(--green);
}

header .navbar {
  border-top: 48px solid var(--green);
  position: relative;
}
header .navbar:after {
  content: "";
  position: absolute;
  background: var(--white);
  display: inline-block;
  right: 0;
  top: -48px;
  height: 47px;
  width: 50%;
  clip-path: polygon(4% 0, 100% 0, 100% 100%, 0% 100%);
}
nav.navbar.bg-black {
  background: var(--black) !important;
  padding: 0;
}
nav.navbar.bg-black .flex-lg-column.container {
  margin-top: -10px;
}
.align-center {
  align-items: center;
}

.navbar-nav-row-1 {
  margin-top: -56px;
  position: relative;
  z-index: 2;
}
.nav-link-row-1-left {
  color: var(--white) !important;
}
.nav-link-row-1-right {
  color: var(--black) !important;
}
.navbar-nav-row-2 {
  /* margin-top: 15px; */
}
.icon-flag {
  position: relative;
  z-index: -1;
}
.navbar-nav-row-2 img {
  width: 205px;
  height: 56px;
}

.navbar-light .navbar-toggler {
  color: var(--white) !important;
}
.navbar-brand {
  padding: 0 15px;
}
.header_logo {
  vertical-align: middle;
  object-fit: cover;
  width: 150px;
  height: auto;
}

a.nav-link:hover {
  opacity: 0.7;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
@media screen and (min-width: 992px) and (max-width: 1199px) {	
    nav.navbar.bg-black .flex-lg-column.container {
      margin-top: 15px;
  }
  .w-100.order-lg-2.navbar-nav-row-2.navbar-collapse.collapse {
    padding-top: 10px;
    padding-bottom: 10px;
}
.navbar-nav-row-1 {
  margin-top: -56px;

}
}

@media (max-width: 1199px) {
  #responsive-navbar-nav .nav-link {
    margin-right: 15px;
  }
}
@media (min-width: 1200px) {
  div#responsive-navbar-nav .me-auto.navbar-nav .nav-link {
    padding: 1.8rem 0 1.5rem;
  }
  #responsive-navbar-nav .nav-link {
    padding: 1rem 0 1.5rem;
    margin-right: 2.5rem;
    margin-left: 0.5rem;
  }
  svg.svg-inline--fa.fa-facebook-f.fa-w-10 {
    color: black;
  }

  svg.svg-inline--fa.fa-instagram.fa-w-14 {
    color: black;
  }
}

@media (max-width: 991px) {
  header .navbar:after {
    content: unset;
  }
  nav.navbar .container {
    margin-top: 0!important;
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .nav-link-row-1-right {
    color: var(--white) !important;
  }
  .navbar-light .navbar-toggler {
    color: transparent !important;
  }

  a.navbar-contact.nav-link {
    margin-bottom: 50px;
  }
  .align-center {
    align-items: unset;
  }
  .navbar-social {
      flex-direction: row;
      margin-top: 10px;
  }
  header .navbar {
    border: unset;
}
}

