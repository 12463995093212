.modal {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 21;
  transition: all 0.3s ease-out;
}

.modal_menu{
  display: inline-block;
}

.modal_open {
  opacity: 1;
  transform: translateX(0%);
}

.modal_closed {
  opacity: 0;
  transform: translateX(-100%);
}

.flex_col {
  flex-direction: column;
}

.button_navbar {
  margin-bottom: 35px;
  margin-top: 25px;
}


.a_link {
  margin-bottom: 10px;
  color: var(--white) !important;
}

.a_link:hover {
  opacity: 0.7;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.fa_times_dim {
  font-size: 30px;
}

.logo {
  object-fit: cover;
  margin-left: 20px;
  margin-bottom: 20px;
  height: 54px;
  width: 204px;
}
