.details {
  padding: 10px;
  background-color: var(--black);
  margin-bottom: 30px;
  color: white;
}
canvas {
  height: 420px !important;
}

/*banner*/
.hero-image-page {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 100%;
}
.hero-image-page .container {
  height: 50vh;
}

.hero-image-page p {
  font-weight: 300;
  margin-top: 20px;
}

.card-about {
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  padding: 40px;
  background: #fff;
  text-align: center;
  height: calc(100% - 25px);
  margin-top: 25px;
}

.card-about img {
  width: 60px;
  margin-right: 6px;
  margin-bottom: 10px;
}

.card-about h4 {
  margin-bottom: 25px;
}

.about_values {
  display: inline-block;
  text-align: left;
  line-height: 50px;
}

.sec_valori {
  display: flex;
  justify-content: center;
}

.valori {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
}
.valori li {
  display: inline-block;
  width: 100%;
  text-align: left;
}

img.img_hero {
  width: 60px;
  margin-right: 6px;
}

.crops__section {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0% 66%;
  color: #ffffff85;
}
.crop__surface {
  font-size: 2.3rem;
  font-weight: 600!important;
  color: #d2bd48;
}
.crops__section h3 {
  font-size: 16px;
  font-weight: bold;
}

.crop__surface span {
  font-size: 12px;
  display: block;
  font-weight: 600;
  color: #ffffff85;
}
.intro-text-section {
  margin-bottom: 40px;
}

.intro__about {
  position: relative;
  overflow: hidden;
}

/* .intro__about:after {
  content: "";
  display: inline-block;
  width: 100%;
  left: 29%;
  top: 0;
  bottom: 0;
  position: absolute;
  background: url(../../../../public/assets/images/bl1.png) no-repeat 0% -21%;
  background-size: cover;
}
.intro__about > div {
  position: relative;
  z-index: 9;
  background: rgba(240, 240, 240, 0.9);
} */

.hero-image-page .hero-content {
  left: 50%;
  transform: translate(-50%, -45%);
  text-align: center;
}
.page p {
  font-weight: 300;
  margin-bottom: 10px;
}

/* CARD */
.card-seeds .nav-tabs {
  border: 0;
  padding: 15px 0.7rem;
  margin: 0px 15px;
}

.card-seeds .nav-link.active {
  background-color: rgba(255, 255, 255, 0.2)!important;
  color: #ffffff!important;
}

.card-seeds .nav-tabs:not(.nav-tabs-neutral) > .nav-item > .nav-link .active {
  box-shadow: 0px 5px 35px 0px rgba(0, 0, 0, 0.3);
}

.card-seeds .nav-tabs {
  border-top-right-radius: 0.1875rem;
  border-top-left-radius: 0.1875rem;
}

.card-seeds .nav-tabs > .nav-item > .nav-link {
  color: #e3e3e3;
  margin: 0;
  margin-right: 5px;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 30px;
  font-size: 14px;
  padding: 11px 23px;
  line-height: 1.5;
}

.card-seeds .nav-tabs > .nav-item > .nav-link:hover {
  background-color: transparent;
}

.card-seeds .nav-tabs.nav-tabs-neutral > .nav-item > .nav-link {
  color: #ffffff;
}

.card-seeds {
  border: 0;
  border-radius: 0.1875rem;
  display: inline-block;
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
  padding: 0;
}

.card-seeds .card-header {
  background-color: var(--black);
  padding: 5px 0px;
}

.card-seeds .card-body {
  padding: 4rem 4rem;
}


.about_tabs_img{
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  -webkit-box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgb(0 0 0 / 20%);
  box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgb(0 0 0 / 20%);
}

.border-top-left {
  padding: 15px 0 0 18px;
  position: relative;
}
.border-top-left:after, .border-top-left:before {
  content: '';
  background: white;
  position: absolute;
  top: 0;
  left: 0;
}
.border-top-left:after {
  width: 35px;
  height: 5px;
}
.border-top-left:before {
  width: 5px;
  height: 35px;
}
.bg-green, .bg-yellow {
  background-repeat: no-repeat;
  color: #ffffff;
  padding: 120px 0 130px;
  background-size: 100% 100%;
}
.bg-green h2, .bg-yellow h2 {
  color: #ffffff;
}
.bg-green {
  background-image: url(../../../../public/assets/images/verde.png);
  background-position: 100% 0%;
  padding: 260px 0 195px;
  margin-top: -20px;
}
.bg-green .col-10 {
  padding-left: 11%;
}
.bg-yellow {
  background-image: url(../../../../public/assets/images/galben.png);
  background-position: 25% 0%;
  margin-top: -160px;
  padding: 140px 0 150px;
}
.mb-50 {
  margin-bottom: 50px;
}
.container_grid {
  display: grid; 
  grid-template-columns: 33.3% 33.3% 33.3%; 
  grid-template-rows: 1fr 1fr; 
  gap: 0px 0px; 
  grid-template-areas: 
    "tgrre_0 tgrre_1 tgrre_2"
    "tgrre_3 tgrre_4 tgrre_2"; 
}
.tgrre_0 { grid-area: tgrre_0; }
.tgrre_1 { grid-area: tgrre_1; }
.tgrre_2 { grid-area: tgrre_2; }
.tgrre_3 { grid-area: tgrre_3; }
.tgrre_4 { grid-area: tgrre_4; }


@media (max-width: 1000px) {
  .about_tabs_img{
    display: none;
  } 
}

@media (max-width: 991px) {
  .bg__black__op {
    min-height: 444px;
    width: 90%;
    margin: 15px auto 30px;
    
  }
  .card-seeds .nav-tabs {
    margin: 0;
  }
  .bg-yellow {
    margin-top: -200px;
    padding: 120px 0 80px;
  }
  .hero-image-page .container {
    height: 32vh;
}
}

@media (max-width: 767px) {
  .page_about .intro__about img {
    width: 85%!important;
}
  .container_grid {
    grid-template-columns: 90%; 
    grid-template-rows: auto auto auto auto auto; 
    grid-template-areas: 
      "tgrre_0"
      "tgrre_1"
      "tgrre_2"
      "tgrre_3"
      "tgrre_4"; 
  }
  

  .key__operational p {
    padding: 0px 12% 5px;
}
  .row-values h2 {
    margin-bottom: 10px;
    
  }
  .row-values .box__cta__btn {
    margin-top: 30px;
  }
  .page_about .hero-content{
    width: 80%;
  }

  .hero-image-page .container {
    height: 37vh;
  }
  .sec_valori {
    display: block;
    justify-content: unset;
  }
  .valori {
    flex-direction: row;
  }
  .valori li {
    width: 45%;
  }

  .intro__about:after {
    left: 2%;
  }

  .row > .col-md.mb-4.mb-md-2.about-seeds {
    width: 50%;
  }

  .crops__section.text-center .container > .row {
    justify-content: center !important;
  }

  .about-seeds h3:after {
    content: "(Ha)";
    margin-left: 8px;
    font-weight: 500;
    font-size: 12px;
  }
  .about-seeds p.crop__surface span {
    display: none;
  }
  canvas {
    height: 350px !important;
  }

  .card-about {
    height: auto;
  }

  .margin-mobile-about {
    margin: 0 15px;
  }

  .card-seeds .card-body {
    padding: 2rem 1.5rem;
  }
  .bg-green {
    background-image: url(../../../../public/assets/images/verde-mobil.png);
    padding: 90px 0 55px;
    margin-top: 30px;
  }
  .bg-green .col-10 {
    padding-left: 35px;
    padding-right: 35px;
  }
  .bg-yellow {
    background-image: url(../../../../public/assets/images/galben-mobil.png);
    margin-top: -44px;
    padding: 60px 5px 15px 15px;
  }
  .bg-yellow .border-top-left {
    margin-bottom: 40px;
  }
  .text-center-mb {
    text-align: center;
  }
  .no-paddings-mobile {
    padding: 0!important;
  }
  .mb-50 {
    margin-bottom: 0;
}
}

@media (max-width: 375px) {
  .about-seeds {
    flex: unset !important;
  }
}


