:root {
  --green: #d2bd48;
  /* --black: #0d181d; */
  --black: #1b3d2f;
  --red: #de1f26;
  --white: #fff;
  --title: 64px;
  --description: 23px;
  --text: 18px;
  --text_small: 15px;
}
.navbar-nav .nav-link {
  color: var(--white);
}
.navbar-nav .nav-link.active, .navbar-nav .show>.nav-link .navbar-nav a.nav-link {
  color: var(--white);
  position: relative;
  font-weight: 500;
}
.navbar-nav .ic-ropdown-item.nav-link.active:after {
  width: calc(110% - 40px);
  left:-1%;
}
.navbar-nav .nav-link.active:after {
  content: '';
  background: transparent;
  display: inline-block;
  width: 110%;
  height: 3px;
  left: -5%;
  position: absolute;
  bottom: 0;
}
.navbar-nav .nav-link.active:after {
  background: var(--green);
  
}
.navbar-social svg.svg-inline--fa.fa-instagram.fa-inverse {
  font-size: 18px;
  margin-top: 4px;
}
nav.navbar.navbar-expand-lg .navbar-social svg.svg-inline--fa.fa-instagram.fa-inverse {
  color: var(--black);

}
/*Carousel start*/
.crops__section ul.slick-dots {
  bottom: -25px;
}

.slick-slider .crop__surface {
margin: 0;
}
.crops__section .slick-dots li button:before {
font-size: 10px;
}
.slick-prev,
.slick-next {
z-index: 9;

}
h1,
h2,
h3,
h4,
h5 {
  font-weight: 600;
}
h3 {
  color:var(--black);
  font-size: 32px;
}
.crops__section .slick-prev:before, .crops__section .slick-next:before {
color: transparent;
font-size: 24px;
}

.slick-prev {
left: 30px;
}
.slick-next {
right: 30px;
}
.slick-prev:before {
background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3C!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg fill='%23d2bd48' version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 330 330' style='enable-background:new 0 0 330 330;' xml:space='preserve'%3E%3Cpath id='XMLID_92_' d='M111.213,165.004L250.607,25.607c5.858-5.858,5.858-15.355,0-21.213c-5.858-5.858-15.355-5.858-21.213,0.001 l-150,150.004C76.58,157.211,75,161.026,75,165.004c0,3.979,1.581,7.794,4.394,10.607l150,149.996 C232.322,328.536,236.161,330,240,330s7.678-1.464,10.607-4.394c5.858-5.858,5.858-15.355,0-21.213L111.213,165.004z'/%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3C/svg%3E%0A");
}
.slick-next:before {
background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3C!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg fill='%23d2bd48' version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 330.002 330.002' style='enable-background:new 0 0 330.002 330.002;' xml:space='preserve'%3E%3Cpath id='XMLID_103_' d='M233.252,155.997L120.752,6.001c-4.972-6.628-14.372-7.97-21-3c-6.628,4.971-7.971,14.373-3,21 l105.75,140.997L96.752,306.001c-4.971,6.627-3.627,16.03,3,21c2.698,2.024,5.856,3.001,8.988,3.001 c4.561,0,9.065-2.072,12.012-6.001l112.5-150.004C237.252,168.664,237.252,161.33,233.252,155.997z'/%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3C/svg%3E%0A");
}
.crops__section .slick-prev, .crops__section .slick-next {
top: 45%;
}
/*Carousel End*/
/* .bg-black-opacity {
  background-color: unset !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-image: url(../../../public/assets/images/220328/);
  min-height: 124vh;
} */
.hero-content {
  position: absolute;
  top: 44%;
  transform: translateY(-50%);
  color: var(--black);
  width: 65%;
}

#root {
  overflow: hidden;
}
#page .bg-black {
  background-color: #0d181d !important;
}
body {
  font-family: "Montserrat", sans-serif;
  background: #fff;
}
/* links start*/

.cta-link {
  color: var(--white);
  text-decoration: unset;
}

/* links start*/


.card-seeds .tab-pane p {
  white-space: pre-wrap;
}


.ic-ropdown-item .nav-link.active.dropdown-item {
  margin: 0!important;
  padding: 15px 15px!important;
}
.navbar-nav .ic-ropdown-item .nav-link.active:after {
  content: unset!important;
}
.dropdown-menu.show {
  width: 238px;
  top: 62px;
  /* box-shadow: rgb(0 0 0 / 30%) 0px 2px 6px 0px; */
  padding: 0;
  border-radius: unset;
  background: var(--black);
  border: unset;
  left: -2px;
}
a.dropdown-item {
  padding: 13px 16px;
  border-bottom: 1px solid #144e3a;
  color: #fff;
  font-size: 16px;
}
a.dropdown-item:last-child {
  border: unset;
}
.dropdown-item.active, .dropdown-item:active, .dropdown-item:focus, .dropdown-item:hover {
  background: #144e3a;
  color: var(--white);
  opacity: 0.7;
}


.navbar-nav .show>.nav-link {
  color: var(--white);
}



p,
a {
  font-size: var(--text);
  font-weight: 500;
}
a {
  font-weight: 500;
}
p {
  font-size: var(--text);
  font-weight: 300;
}

.link_ro {
  position: absolute;
  top: 25px;
  right: 22px;
}

.mobile__only {
  display: none;
}

@media (max-width: 767px) {
  header .navbar {
    border: unset;
  }
  .desktop__only {
    display: none;
  }
  .mobile__only {
    display: block;
    padding: 0;
  }
}
@media (min-width: 576px) {
  .container, .container-sm {
    max-width: calc(100% - 30px);
  }
}


@media (min-width: 768px){
  .container, .container-md, .container-sm {
    max-width: calc(100% - 30px);
  }
}

@media (min-width: 1200px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
      max-width: 1140px;
  }
  .h1,
  h1 {
    font-size: var(--title);
    font-weight: 400;
    margin-bottom: 30px;
    line-height: 1;
  }
}
@media (min-width: 1400px) {


  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1262px;
  }
}

/*Btn start*/
.box__cta__btn {
  display: inline-block;
  overflow: hidden;
  border-radius: 7px;
  background: var(--green);
  margin-top: 40px;
}
.cta__btn {
  position: relative;
  color: var(--white);
  text-decoration: unset;
  text-transform: uppercase;
  width: 100%;
  height: 100%;
  font-weight: 600;
  font-size: var(--text_small);
  display: inline-block;
  padding: 18px 62px 18px 30px;
}
.cta__btn:after {
  background: var(--white);
  content: "";
  height: 155px;
  left: -125px;
  opacity: 0.4;
  position: absolute;
  top: -50px;
  transform: rotate(35deg);
  transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
  width: 50px;
  z-index: 1;
}
.cta__btn:hover {
  color: #fff;
}
.cta__btn:hover:after {
  left: 120%;
  transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
}

.cta__btn:before {
  content: "";
  position: absolute;
  right: 30px;
  display: inline-block;
  transform: translate(0, -50%) scale(-1, 1);
  background: url(../../../public/assets/images/left-arrow-white.png);
  top: 42%;
  height: 23px;
  width: 20px;
}
.cta__btn:hover:before {
  transform: translate(0.4em, -50%) scale(-1, 1);
}
/*Btn end*/

.section__padding {
  padding-top: 70px;
  padding-bottom: 70px;
}
.h2,
h2 {
  margin-bottom: 25px;
  color: var(--black);
  font-weight: 600;
}

.bg-black3-opacity {
  background-color: rgba(0, 0, 0, 0.3);
}
.description-section {
  font-size: 20px!important;
  font-weight: 300!important;
}
/* Form Invalid */
.invalid {
  border: 1px solid #ff8800 !important;
  background: #fbe8d2 !important;
}

/*nou*/
@media (min-width: 1200px) {
  .h2,
  h2 {
    font-size: 3.2rem;
  }
}

@media (max-width: 767px) {
  .crops__section ul.slick-dots {
    bottom: -10px;
}
  p {
    font-size: 15px;
  }
  .row.align-items-center {
      justify-content: center;
  }
  .h1, h1 {
    font-size: calc(1.375rem + 2.2vw);
  }
  .description-section {
    font-size: 15px!important;
  }
}

@media (max-width: 575px) {
  .section__padding {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.MenuOverlay_fa_times_dim__UJExX {
  font-size: 22px!important;
}