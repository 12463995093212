.post{
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    border-bottom: 3px var(--green) solid;
}

.card{
    box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
    background: #f0f0f0;
    height: 100%;
    margin-top: 25px;
}

.post img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 0;
}

.post_left{
    width: 50%;
}

.post_right {
    width: 50%;
    padding: 30px 25px 25px 35px;
    position: relative;
}

.post_right p:last-of-type{
    margin-bottom: 90px;
}

.button_media{
    position: absolute;
    bottom: 0;
    margin-bottom: 50px;
}

@media (max-width: 700px){
    .post{
        flex-direction: column;
        width: 95%;
        margin: auto;
        border-left: 3px var(--green) solid;
        border-bottom: 0;
    }
    .post_left, .post_right{
        width: 100%;
        margin: auto;
        
    }
    .post_right{
        padding: 30px 15px 15px 15px;
    }

}

