.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  background-color: rgb(0, 0, 0);
  opacity: 0.9;
  transition: all 0.3 fade-in;
}

.backdrop_open {
  opacity: 0.9;
}

.backdrop_closed {
  display: none;
  opacity: 0;
}
