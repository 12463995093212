.row_list_values img {
  width: 50px;
}

.about__us img {
  width: 100%;
}
.about__us .cta__btn,
.about__us .cta__btn:hover {
  color: #fff;
}
.about__us .cta__btn:before {
  /* background: url(../../../../../public/assets/images/left-arrow-white.png); */
  top: 42%;
  height: 23px;
  width: 20px;
}
.text-left {
  text-align: left;
}
.title__section_crops {
  font-size: 22px;
  margin-bottom: 20px;
  margin-top: 15px;
}


/*farm organization start*/
.farm__organization {
  background-color: var(--black);
  position: relative;
  color: var(--white);
}
.polygon__organization {
  position: absolute;
  top: 0;
  bottom: 0;
}
.bg__organization {
  right: 0;
  width: 50%;
  clip-path: polygon(11% 0, 100% 0, 100% 100%, 0% 100%);
}
.border__organization {
  right: 44%;
  width: 8%;
  clip-path: polygon(90% 0%, 100% 0, 25% 100%, 15% 100%);
  background: var(--green);
}
.farm__organization .row > div {
  min-height: 380px;
}
.box__center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  padding: 60px 0;
}
/*farm organization end*/

/*key operational start*/
.bg__black__op {
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  padding: 30px;
  text-align: center;
  border-radius: 7px;
  min-height: 448px;
  height: 100%;
}
.bg__black__op:hover,
.card-about:hover {
  transform: scale(1.09);
}
.key_box_0 .key_table_1:before {
  content: 'Proiecte Noi';
  display: inline-block;
  margin-top: 20px;
  text-align: left;
  margin-bottom: 10px;
  width: 100%;
  color: var(--green);
  font-size: 12px;
  font-weight: 500;
}
.poim {
  background: var(--green);
  position: relative;
}
.poim:after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: url(../../../../public/assets/images/231222/poim.png);

  background-size: 150%;
  background-position: 100% 98%;
  background-repeat: no-repeat;
  z-index: 1;
}
.ct-poim {
  position: relative;
  z-index: 3;
}
.poim h2 {
  margin-bottom: -10px;

}
.poim .cta__btn {
  background: var(--black)!important;
}
.key__operational img {
  width: 75px;
  height: 75px;
  padding: 7px;
  display: inline-block;
}
.key__operational p {
  padding: 0 20% 30px;
}
.key__operational h3 {
  font-size: 18px;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 20px;
}
table {
  width: 100%;
}
th,
td {
  font-size: 12px;
  padding: 5px;
}

.key_table_0 tr:first-child td {
  background: rgba(190, 171, 62, 0.6);
  color: #fff;
  border: 1px solid #f0f0f0;
  font-weight: 700;
}
td {
  border: 1px solid #beab3e;
}

.key__operational i {
  display: inline-block;
  width: 100%;
  text-align: justify;
  margin-top: 20px;
  font-size: 12px;
  padding: 0 3px;
  text-align-last: center;
  color: var(--green);
}

.key__operational .cta__btn,
.key__operational .cta__btn:hover {
  color: #fff;
}
.key__operational .cta__btn:before {
  /* background: url(../../../../../public/assets/images/left-arrow-white.png); */
  top: 42%;
  height: 23px;
  width: 20px;
}

/*contact start*/
.contact__us {
  background-color: var(--green);
  padding: 0;
  display: none;
}
.contact__us .section__padding {
  position: relative;
  z-index: 9;
}
.bg__contact__us {
  background-color: rgba(0, 0, 0, 0.45);
  color: #fff;
  min-height: 482px;
  display: inline-block;
  width: 100%;
}

.bgimg__contact__us {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
.contact__us img {
  width: 100%;
  max-height: 246px;
  object-fit: cover;
  object-position: center;
}
/*list start contact*/
.display-flex-center {
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 18px;
  display: flex;
  align-items: center;
}
.display-flex-center img {
  margin-right: 20px;
  width: 25px;
  height: 25px;
}

/*form*/
.form {
  background: var(--black);
  padding: 40px;
  position: absolute;
}
input[type="text"],
textarea {
  background: #f0f0f0;
  padding: 15px 20px;
  width: 100%;
  border: unset;
  margin-bottom: 15px;
}
.form label a {
  color: #fff;
  font-size: 16px;
}
textarea {
  min-height: 127px;
}
.form-description {
  font-size: 14px;
  font-weight: 300;
  line-height: 19px;
  display: inline-block;
  margin-bottom: 25px;
  padding-right: 5%;
}
.checkbox {
  display: flex;
}
input[type="checkbox"] {
  position: relative;
  top: 4px;
  margin-right: 20px;
}
.bg__contact__us .col-5 {
  position: relative;
}



/*Asset Carousel start*/
.key_box {
  padding: 20px;
}
.slick-dots li button:before {
  font-size: 18px;
  opacity: 0.5;
  color: var(--black);
  background-size: cover;
}

.slick-dots li.slick-active button:before {
  opacity: 1;
  color: var(--green);
}

ul.slick-dots {
    bottom: 10px;
}

.home-page .assets-carousel button.slick-arrow.slick-next,
.home-page .assets-carousel button.slick-arrow.slick-prev {
  display: none!important;
}

.slick-prev,
.slick-next {
  width: 35px;
  height: 35px;
}
.home-page .slick-prev:before,
.home-page .slick-next:before {
  content: "" !important;
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 0.5;
}
/*Asset Carousel end*/



@media (min-width: 2200px){

  .poim:after {

    background-size: cover;
  }
}
@media (max-width: 1445px){
  .home-page p.hero-description br {
    display: none;
  }
  .home-page p.hero-description {
    width: 62%;
  }
  .home-page .hero-image img, .hero-image video {
    width: 124%;
  }
  .home-page .bg__black__op {
      min-height: 496px;
  }
  .poim:after {

    background-size: 180%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .home-page .bg__black__op {
    min-height: 529px;
}
}

@media (min-width: 913px) and (max-width: 991px) {
  .home-page p.hero-description {
    width: 56%!important;
  }
}
@media (max-width: 991px){
  .poim h2 {
    margin-bottom: 0;
  
  }
  .poim:after {

    background-size: 270%;
  }
  .home-page p.hero-description {
    width: 56%;
  }
  .home-page p.hero-description {
    width: 90%;
}
.home-page .container.section__padding.about__us p {
  width: 75%;
}
}
@media (max-width: 912px){
  .home-page .hero-image img, .hero-image video {
    width: 100%;
  }
}

@media (max-width: 767px){
  .border__organization {
    display: none;
  }
  .bg__organization {
    display: none;
  }
  .box__center {
    text-align: center;
  }
  .home-page .container.section__padding.about__us p {
    width: 100%;
  }
  .farm__organization .row > div {
    min-height: auto;
}
}

@media (max-width: 575px) {
  .box__center {
    padding: 50px 0;
  }
}


