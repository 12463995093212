.hero-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.bg-black-opacity {
  background-color: rgba(0, 0, 0, 0.5);
}
.bg-black2-opacity {
  background-color: rgba(0, 0, 0, 0.2);
}
.position_absolute {
  position: absolute;
  top: 304px;
  width: 100%;
}
.container.section__padding.about__us {
  margin-top: -302px;
  position: relative;
}
.hero-content {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: var(--white);
  width: 100%;
}
.hero-image video {
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  z-index: -1;
}
.hero-description {
  font-size: 22px;
  font-weight: 400;
  margin-top: 30px;
  margin-bottom: 20px;
}
.hero-content h1 {
  margin-bottom: 10px;
  font-weight: 700;
}
.hero-content p {
  font-size: var(--description);
}
.home-page .hero-content p {
  color: var(--white);
}
.our__values {
  font-weight: 600;
  font-size: 25px;
  color: var(--white) !important;
}


@media (max-width: 912px) {
  .hero-image.position-relative img {
    margin-top: 200px;
  }

  .hero-image video {
    height: 645px;
    object-fit: cover;
  }

  .container.section__padding.about__us {
    margin-top: -160px;
  }



  .box__center {
    padding-bottom: 20px;
    padding-top: 20px;
  }
}

@media (max-width: 767px) {
  .hero-description br {
    display: none;
  }
  .box__cta__btn {
    margin-top: 10px;
  }
  .hero-description {
    margin-top: 10px;
    font-size: 19px;
    font-weight: 500!important;
  }
  p.hero-description {
    font-size: 1rem;
    font-weight: 500;
  }
  .hero-image video {
    width: 150%;
  }
  .hero-section-img {
    margin-top: 100px;
  }
  .hero-image.position-relative {
    background: rgba(0, 0, 0, 0.1) !important;
  }
  .position_absolute {
    top: 27%;
    left: 15px;
  }
  .hero-content {
    width: 80%;
  }
  .container.section__padding.about__us {
    text-align: center;
    margin-top: -40px;
    padding-top: 0;
  }
  .cta__btn {
    padding: 13px 57px 13px 23px;
    text-transform: initial;
  }
}

@media (max-width: 576px) {
  .hero-image.position-relative img {
    margin-top: 100px;
    height: 630px;
    object-fit: cover;
    object-position: 24% 100%;
  }

  .hero-image video {
    height: 450px;
    object-fit: cover;
  }

  .container.section__padding.about__us {
    margin-top: -70px;
    text-align: center;
    padding-top: 0;
  }

}

@media (max-width: 280px) {
  .hero-image.position-relative img {
    margin-top: 125px;
  }
}
