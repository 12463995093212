.page_farm {
  background: #c0ddfd;
}
.page_farm .hero-image-page {
  background-position: 50% 45%;
}
.page_farm h3 {
  color: var(--green);
  margin-bottom: 15px;
}
.page_farm ul{
  list-style: none;
  padding: 0;
}
.section__timeline {
  position: relative;
  background-image: url(../../../../public/assets/images/220830/bottomfinal22.png); 
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 100%;
}
.list_items .row__timeline:nth-child(n) .col-md-6 {
  padding-right: 70px;
}
.list_items .row__timeline:nth-child(2n) .col-md-6 {
  margin-left: 50%;
  padding-left: 70px;
}
.list_items .row__timeline:first-child,
.list_items .row__timeline:last-child{
  position: relative;
}
.list_items .row__timeline:first-child:after,
.list_items .row__timeline:last-child:after  {
  content: '';
  display: inline-block;
  background: #fff;
  width: 9px;
  position: absolute;
  left: calc(50%);
  transform: translateX(-50%);
  z-index: 1;
}
.list_items .row__timeline:first-child:after {
  top: 0;
  height: calc(50% - 50px);
}
.list_items .row__timeline:last-child:after {
  bottom: 0;
  height: calc(50% + 50px);
}
.section__timeline:after {
  content: '';
  display: inline-block;
  width: 8px;
  height: 94%;
  background: var(--green);
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 0;
}
.row__height.row__timeline .row {
  min-height: 427px;
}
.img_sol {
    width: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    position: relative;
    z-index: 3;
    height: 317px;
}
.list_items {
  margin-bottom: -317px;
  margin-top: -50px;
}
.list_items .row__timeline:not(:first-child) {
  margin-top: -165px;
}
.list_items .bg-white {
  padding: 0 40px;
}
.list_items .row__timeline:last-child .bg-white {
  padding-bottom: calc(100px + 50px);
}
.page_farm .position-relative {
  z-index: 3;
}
.icon-frunza {
  display: inline-block;
  width: 100px;
  height: 100px;
  background-image: url(../../../../public/assets/images/icons/leaf.png); 
  background-size: 50%;
  background-color: var(--green);
  border-radius: 50%;
  position: relative;
  z-index: 2;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.list_items li,
.list_items p {
  font-size: 18px;
  font-weight: 300;
}
.crops__section h3 {
  color: #ffffff85;
}
@media (max-width: 991px){
  .icon-frunza {
    width: 80px;
    height: 80px;
  }
  .list_items .row__timeline:first-child:after {
      height: calc(50% - 30px);
  }
  .row__timeline h3 {
    font-size: 24px;
    font-weight: 600;
}

}


@media (max-width: 767px){
  .list_items .row__timeline:nth-child(2n) .col-md-6 {
    margin-left: 0;
  }
  .list_items {
    margin-top: 0;
    margin-bottom: -100px;
}
.list_items li,
.list_items p {
  font-size: 15px;
}
.list_items .row__timeline:nth-child(n) .col-md-6,
.list_items .row__timeline:nth-child(2n) .col-md-6 {
  padding: 0;
}
.icon-frunza,
.section__timeline:after {
  background-color: #fff;
}
.list_items .bg-white {
  padding: 15px;
}
.list_items .row__timeline:not(:first-child) {
  margin-top: 0;
}
.row__height.row__timeline .row {
  min-height: auto;
}
.page_farm .description-section {
  padding: 0 15px;
}
.icon-frunza {
  width: 60px;
  height: 60px;
}
.page_farm > .container {
  padding: 0 15px;
}
.img_sol {
  height: 170px;
}
.list_items .row__timeline:last-child .bg-white {
  padding-bottom: 100px
}
.section__timeline {
  background-position: 34% 95%;
}
}
