/*footer*/

.footer {
  color: var(--black);
  background: #f0f0f0;
}
.footer p {
  margin: 10px 0;
  font-size: 14px;
  font-weight: 500;
}

.footer-logo {
  height: 56px;
}

/*footer1*/
.footer.footer1 {
  background: var(--black);
  padding: 60px 0 55px;
}
.footer1 .col-3 img {
  width: 70%;
  position: relative;
  left: -6px;
}
.footer1 h3 {
  font-size: 18px;
  margin-bottom: 35px;
  font-weight: 600;
  color: #f0f0f0;
}
.list-company a {
  color: #ababab;
  width: 100%;
  display: inline-block;
  text-decoration: unset;
  margin-bottom: 10px;
  font-size: 16px;
}
.footer label a {
  color: #fff;
  font-size: 16px;
}
.footer label {
  color: #f0f0f0;
}
.footer .mapouter, 
.footer .gmap_canvas, 
.footer iframe {
  height: 254px;
  padding-left: 30px;
}

span.description-footer {
  margin: 38px 0px 20px;
  font-weight: 500;
  display: inline-block;
  color: #ababab;
  padding-right: 14%;
}
.footer input[type="text"] {
  border-radius: 4px;
}

.link-footer:hover {
  color: var(--white);
  transition: all 0.4s ease-in-out;
}

.footer_map {
  height: 300px !important;
  width: 400px !important;
}

@media (max-width: 991px) {
  .footer1 h3 {
    margin-bottom: 15px;
  }

  .list-company {
    margin-bottom: 20px;
  }

  .footer-newsletter {
    margin-top: 10px;
  }
  .footer_map {
    width: 100% !important;
  }
}

@media (max-width: 767px) {
  .footer.footer1 {
    text-align: left;
  }
  .footer-logo {
    margin-left: 20px;
    height: 41px;
    margin-bottom:-10px;
  }
  span.description-footer {
    padding-right: 0px;
    text-align: left;
    margin-left: 20px;
  }
  .footer-info {
    text-align: left;
    margin-left: 15px;
  }
  .list-company a {
    margin-bottom: 10px;
    font-size: 15px;
  }
  .nav-link {
    font-size: 15px;
}

element.style {
  border: 0px;
}
  iframe {
    padding: 0 15px;
  }
}
