.container__page__error {
  min-height: calc(100vh - 606px);
  text-align: center;
}
.container__page__error h1 {
  color: var(--black);
}

@media (max-width: 700px) {
  .container__page__error .hero-content {
    text-align: center;
  }
}
