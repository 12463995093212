.page_media .hero-image-page {
  background-position: 50% 60% !important;
}
.page.SinglePost_page_post__IqvvJ .bg-black2-opacity {
  min-height: 600px;
}
.page.SinglePost_page_post__IqvvJ .hero-image-page .hero-content {
  left: 50%;
  transform: translate(-50%, -30%);
  text-align: center;
}
@media (max-width: 700px) {
  .page_media .section__padding {
    padding-left: 25px;
    padding-right: 25px;
  }

  .page_post_padding{
    padding-left: 25px;
    padding-right: 25px;
  }
  .page.SinglePost_page_post__IqvvJ .bg-black2-opacity {
    min-height: 414px;
}
}
