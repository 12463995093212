.box_cta_btn {
  display: inline-block;
  overflow: hidden;
}

.cta_btn {
  position: relative;
  border-radius: 7px;
  background: var(--green);
  margin-top: 20px;
  border: transparent;
  text-decoration: unset;
  text-transform: uppercase;
  font-weight: 600;
  font-size: var(--text_small);
  padding: 18px 62px 18px 30px;
  color: #fff;
  overflow: hidden;
  z-index: 2;
}

.cta_btn::before {
  background: var(--white);
  content: "";
  height: 155px;
  opacity: 0.4;
  position: absolute;
  transform: rotate(35deg);
  transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
  width: 50px;
  z-index: 1;
  margin-left: -116px;
  margin-top: -80px;
}

/* http://localhost:3000/assets/images/left-arrow.png */

.cta_btn::after {
  content: "";
  position: absolute;
  display: inline-block;
  width: 22px;
  height: 22px;
  background: url(../../../../../public/assets/images/left-arrow-white.png);
  background-repeat: no-repeat;
  background-size: cover;
  transform: scale(-1, 1);
  margin-left: 10px;
}

.cta_btn:hover::before {
  margin-left: 230px;
}

.cta_btn:hover::after {
  margin-left: 20px;
}
