.page_post h1{
    /* font-size: 2rem; */
}

.page_post h2{
    margin-bottom: 30px;
}

.page_post h3{
    margin-top: 30px;
    margin-bottom: 10px;
}

.source, .source a{
    margin-top: 50px!important;
    font-size: 1.5rem;
    color: var(--green);
}

@media (max-width: 700px){
    .page_post .section__padding{
        padding-left: 25px;
        padding-right: 25px;
    }
    .page_post h1{
        font-size: 1.5rem;
        width: 80%!important;
        margin: auto;
    }
    .page_post h2{
        font-size: 1.5rem;
    }
    .page_post h3{
        font-size: 1.4rem;
    }
    .page_post .section__padding{
        width: 90%!important;
    }
    .page_post .hero-content{
        width: 80%!important;
    }
}
