.page_farm_organization .img_hero {
  min-height: 469px;
  object-fit: cover;
  object-position: center;
}
.page_farm_organization .hero-image-page {
  background-position: 50% 60%;
}
.row_crops_and_farms .col-md-6:first-child {
padding-right: 8%;
}

.row_crops_and_farms:nth-child(2n) .col-md-6:first-child {
  order: 2;
  padding-right: 0;
  padding-left: 8%;
}
.row_crops_and_farms:nth-child(2n) {
  padding-top: 0!important;
}

@media (max-width: 767px) {
  .row_crops_and_farms:nth-child(2n) .col-md-6:first-child {
    order: unset;
  }
  .row_crops_and_farms .col-md-6 {
    padding: 0 10px!important;
}
    .page_farm_organization .img_hero {
      min-height: 200px;
  }
  .page_farm_organization .h2, 
  .page_farm_organization h2 {
    margin-bottom: 15px;
  }
}
